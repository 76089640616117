<template>
  <div class="actions-bar d-flex justify-center">
    <ImportWorkers
      @close="closeDialog"
      :isOpen="isOpen"
      @success="handleSuccess"
    />
    <SecondaryButton
      @click.native="openDialog"
      permission="create"
      color="primary"
    >
      <div class="d-flex align-center justify-center mb-1">
        <v-icon>mdi-account-group-outline</v-icon>+
      </div>
      New Workers
    </SecondaryButton>
  </div>
</template>

<script>
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import ImportWorkers from "@/views/workers/NewWorker/ImportWorkers";

export default {
  components: {
    SecondaryButton,
    ImportWorkers
  },
  data() {
    return {
      isOpen: false,
      contractType: null
    };
  },
  methods: {
    openDialog() {
      this.isOpen = true;
      document.title = "New Workers";
    },
    closeDialog() {
      this.isOpen = false;
      document.title = "Workers";
    },
    handleSuccess() {
      this.closeDialog();
      this.$emit("success");
    }
  }
};
</script>
