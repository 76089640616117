<template>
  <div class="inline-filters">
    <div
      v-for="(filterOptions, filterKey, index) in availableFilters"
      :key="index"
    >
      <InlineFilter
        v-if="size(filterOptions)"
        :label="getFilterTitle(filterKey)"
        :filters="filters[filterKey]"
        :options="filterOptions"
        @clear="handleClear(filterKey)"
      >
        <FilterCheckbox
          :options="filterOptions"
          :selectedValues="filters[filterKey]"
          @change="values => updateFilterFor(filterKey, values)"
        />
      </InlineFilter>
    </div>
  </div>
</template>

<script>
import { size } from "lodash";
import InlineFilter from "@/components/common/InlineFilter";
import FilterCheckbox from "@/components/common/FilterCheckbox";
import { filterMixin } from "@/mixins/filter.mixin";

export default {
  name: "WorkerListFilter",
  mixins: [filterMixin],
  components: {
    InlineFilter,
    FilterCheckbox
  },
  props: {
    isLoading: Boolean,
    availableFilters: Object,
    outerSelectedFilters: Object
  },
  data() {
    return {
      filters: Object.assign({}, this.outerSelectedFilters)
    };
  },
  methods: {
    size,
    getFilterTitle(filterName) {
      switch (filterName) {
        case "organisation_id":
          return "Agency Supplier";
        case "income_type":
          return "Income Type";
        case "status":
          return "Status";
        default:
          return "";
      }
    }
  }
};
</script>
