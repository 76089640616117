<template>
  <div class="list-item with-icon my-1" :class="{ selected: isSelected }">
    <v-list-item-avatar min-width="60px" height="60px" tile>
      <v-img cover :src="icon" v-if="isSelectMultiple" />
      <v-img height="30px" contain src="@/assets/svg/user-icon.svg" v-else />
    </v-list-item-avatar>
    <v-list-item-content>
      <div class="item-right">
        <div class="description py-1">
          <div class="d-flex" :class="{ 'flex-column': isMobile }">
            <h3>{{ candidate | fullName }}</h3>
          </div>
          <div class="d-flex" :class="{ 'flex-column': isMobile }">
            <span>{{ candidate.email }}</span>
            <span class="mx-1" :class="{ hide: isMobile }"> | </span>
            <span>{{ candidate.agency }}</span>
          </div>
        </div>
      </div>
    </v-list-item-content>
  </div>
</template>

<script>
export default {
  props: {
    candidate: {
      type: Object,
      required: true
    },
    isSelectMultiple: Boolean,
    isSelected: Boolean
  },
  computed: {
    icon() {
      const icon = this.isSelected ? "selected_icon" : "unselected_icon";
      return require(`@/assets/svg/${icon}.svg`);
    }
  }
};
</script>

<style lang="scss">
.workers {
  pading: 0px;
  .list-item {
    cursor: pointer;
    border-radius: 5px;
    padding: 0;
  }
  .list-item.selected {
    background-color: $selected-transparent;
  }
  .v-avatar.v-list-item__avatar.v-avatar--tile:hover {
    border: 2px solid $primary;
  }
  .list-item .v-avatar.v-list-item__avatar {
    background-color: $_purple_light;
  }
}
</style>
