<template>
  <DialogFull :data="workerDialog" @close="close">
    <template v-slot:dialog.body>
      <div @paste="onPaste" class="container">
        <h1 class="my-4 container">New Workers</h1>
        <BulkWorkers ref="bulkWorkers" @update="updateWorkers" />
      </div>
    </template>
    <template v-slot:dialog.action>
      <PrimaryButton
        :disabled="disabled"
        :loading="isCreating"
        @click.native="createWorkers"
      >
        Create Workers
      </PrimaryButton>
    </template>
  </DialogFull>
</template>

<script>
import DialogFull from "@/components/common/DialogFull";
import { mapActions } from "vuex";
import { ADD_NEW_WORKER } from "@/store/modules/workers/action-types";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { map, isEmpty } from "lodash";
import { POST_GLOBAL_MESSAGE } from "@/store/modules/global/action-types";
import BulkWorkers from "@/components/workers/BulkWorkers";

export default {
  components: {
    DialogFull,
    PrimaryButton,
    BulkWorkers
  },
  props: {
    isOpen: Boolean
  },
  data() {
    return {
      isCreating: false,
      workers: []
    };
  },
  computed: {
    workerDialog() {
      return {
        dialog: this.isOpen,
        title: "New Workers"
      };
    },
    disabled() {
      return isEmpty(this.workers);
    }
  },
  methods: {
    ...mapActions("workers", {
      addNewWorker: ADD_NEW_WORKER
    }),
    ...mapActions({
      postGlobalMessage: POST_GLOBAL_MESSAGE
    }),
    close() {
      this.$emit("close");
    },
    async createWorkers() {
      this.isCreating = true;
      const requests = map(this.workers, worker => this.addNewWorker(worker));
      try {
        await Promise.all(requests);
        this.$emit("success");
        this.$refs.bulkWorkers.clearData();
        this.postSuccessMessage();
      } finally {
        this.isCreating = false;
      }
    },
    postSuccessMessage() {
      const message = {
        snackbar: true,
        type: "success",
        title: `Workers added`
      };
      this.postGlobalMessage(message);
    },
    updateWorkers(workers) {
      this.workers = workers;
    },
    onPaste(event) {
      this.$refs.bulkWorkers.onPaste(event);
    }
  }
};
</script>
