<template>
  <div class="actions-bar d-flex justify-center">
    <NewWorker
      ref="newBookingForm"
      @created="$emit('created')"
      @close="closeDialog"
      :isOpen="isOpen"
    />
    <SecondaryButton
      @click.native="openDialog"
      permission="create"
      color="primary"
    >
      <div class="d-flex align-center justify-center mb-1">
        <inline-svg :src="require('@/assets/svg/user-white.svg')" /> +
      </div>
      New Worker
    </SecondaryButton>
  </div>
</template>

<script>
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import NewWorker from "@/views/workers/NewWorker/NewWorker";
import InlineSvg from "vue-inline-svg";

export default {
  name: "NewWorkerAction",
  components: {
    InlineSvg,
    SecondaryButton,
    NewWorker
  },
  data() {
    return {
      isOpen: false,
      contractType: null
    };
  },
  methods: {
    openDialog() {
      this.isOpen = true;
      document.title = "New Worker";
    },
    closeDialog() {
      this.isOpen = false;
      document.title = "Workers";
    }
  }
};
</script>
